.notification-container {
  position: relative;
  display: inline-block;

  &__icon {
    svg {
      width: 24px;
      height: 24px;
      color: gray;
      cursor: pointer;
    }
  }

  .notification-button {
    cursor: pointer;
    display: inline-block;
    border: 1px solid #ccc;
    padding: 5px 10px;
    background-color: #f0f0f0;

    &::after {
      content: "";
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      border-width: 8px;
      border-style: solid;
      border-color: transparent transparent white transparent;
    }

    &:hover {
      + .notification-dropdown {
        display: block;
        opacity: 1;
      }
    }
  }

  .notification-dropdown {
    opacity: 0;
    display: none;
    padding: 20px 25px;
    line-height: 25px;
    min-height: 500px;
    max-height: 600px;
    transition: opacity 0.3s ease-in;
    background-color: #fff;
    position: absolute;
    border-radius: 30px;
    top: 52px;
    right: -75px;
    width: 23vw;
    box-shadow: 0px 3px 8px #00000029;
    border: 1px solid #e3e3e3;

    &.visible {
      display: block;
      opacity: 1;
    }
  }
  .notification-list-container {
    overflow-y: auto;
    min-height: 485px;
    max-height: 485px;
    padding-right: 4px;
  }
  .notification-item {
    padding: 5px;
    border-bottom: 1px solid #ccc;

    p {
      font-size: 14px;
      letter-spacing: 0px;
      color: #595959;

      span {
        font-weight: bold;
      }
    }

    &:last-child {
      border-bottom: none;
    }
  }
}

.notification-contain {
  color: #3a3a3a;
  font-size: 26px;
  font-weight: 500;
  padding-bottom: 15px;
}

.notification-list {
  padding-top: 5px;
}

.no-notification {
  padding-top: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 80px;
}
