.user {
  &-card {
    background-color: #fff;
    box-shadow: 0px 3px 6px #00000008;
    border-radius: 5px;
    padding: 20px;

    :where(.css-dev-only-do-not-override-1xej1fv).ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector,
    .ant-input {
      border-left: 0;
      border-radius: 0;
      border-right: 0;
      border-top: 0;
    }
    .ant-select-selector {
      border: 0px solid !important;
      border-bottom: 1px solid #d9d9d9 !important;
    }
  }
}

.not-expand {
  .ant-table-wrapper {
    min-height: 30vh;
    max-height: 30vh;
    overflow: hidden;
  }
  .ant-table-wrapper:hover {
    overflow: auto;
  }
  .ant-pagination {
    display: none;
  }
}
.expaned {
  .ant-table-content {
    min-height: 64vh;
    max-height: 64vh;
  }
}
.Leave__policy-table {
  .ant-table-cell {
    font-size: 14px !important;
  }

  .leave-approve {
    color: #d34a7c !important;
  }

  .leave-reject {
    color: red !important;
  }

  .card-title h4 {
    border: none !important;
  }
}

.user-bank {
  background-color: #fff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.031372549);
  border-radius: 5px;
}

.earned {
  p {
    letter-spacing: 1.54px;
  }
}

.bg-img {
  position: relative;
  height: 100%;
  background-image: url(https://app.chutti.work/leave_balance_bg.svg);
  background-repeat: no-repeat;
  background-size: 45%;
  background-position: bottom right;
  padding: 20px;

  // &::before {
  //   content: "";
  //   position: absolute;
  //   bottom: -20px;
  //   right: -20px;
  //   width: 168px;
  //   height: 119px;

  //   z-index: 1;
  // }
}

.earn {
  font-weight: 800;
  color: #000;
  letter-spacing: 1px;
}

.Quick__apply_card {
  padding-top: 20px;

  Button {
    width: 160px;
    height: 44px !important;
    font-size: 14px !important;
  }

  .ant-input-outlined:focus-within {
    box-shadow: none !important;
  }
}

.UserAttendance {
  background-color: #fff;
  box-shadow: 0px 3px 6px #00000008;
  border-radius: 5px;
  padding: 35px;
}

.user-birth-anniversary-bottom {
  overflow: hidden;
  background: #ffffff;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.0588235294);
  border-radius: 6px;
  padding: 16px 20px;
  height: 60%;
}

.quick-apply {
  .ant-radio-wrapper span.ant-radio + * {
    font-size: 16px !important;
    font-weight: 400 !important;
  }
}

.no-record.user-announcement {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  align-content: center;
}
.leaveList-container {
  max-height: 40vh;
}

@media screen and (max-width: 1280px) {
  .leaveList-container {
    max-height: 50vh;
  }
}

.applyLeaveModal {
  .ant-modal-close {
    display: none;
  }
  .ant-modal-footer {
    padding-right: 1.6rem;
  }
}
.leavelist-reason {
  max-width: 230px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

// .leave-status::before {
//   content: "";
//   position: absolute;
//   top: 23px;
//   left: 0px;
//   width: 8px;
//   height: 8px;
//   border-radius: 50%;
//   background-color: #6747d4;
// }

.lop-count {
  color: red !important;
  font-size: 12px !important;
}

.user--announcement {
  .announcement-notification-container::after {
    height: 73%;
  }
}

.userDashboard-height {
  height: auto;
  padding: 20px 0;
}
