.ant-table-content {
  overflow: hidden;
  padding-right: 4px;
  &:hover {
    overflow: auto;
  }
}
.employee {
  &-table-container {
    .ant-table {
      &-content {
        height: 55vh;
        overflow-y: auto;
      }
    }
  }
  &-kpi {
    &-table {
      td {
        padding: 0 !important;
        .row {
          padding: 16px;
        }
      }
    }
    .ant-picker {
      border-radius: 0;
    }
    &_table {
      th {
        background-color: #ffeff6 !important;
        text-wrap: nowrap;
        border: 1px solid #c4c4c4 !important;
        border-right: none !important;
        &:nth-child(3),
        &:nth-child(6) {
          border-bottom: none !important;
        }
      }
      td {
        text-align: center;
      }
      tr {
        &:nth-child(2) {
          th {
            border-bottom: 1px solid #c4c4c4 !important;
          }
        }
      }
    }
  }
}
